import {
  Context,
  Image,
  styled,
  css,
  notMobile,
  Text,
  notDesktop,
} from '@obvio/app'
import { SvgArrow2, SvgCloseMenu } from '@obvio/svg'
import { Button, Modal, Section, Stack } from '@obvio/ui'
import { createContext, useContext, useMemo, useState } from 'react'

import { ButtonWithIcon } from '../ButtonWithIcon'
import { CarouselBase } from '../CarouselBase'

import type { ImageAsset } from '@obvio/app'
import type { StateSetter } from '@obvio/utils'
import type { ReactElement } from 'react'

const ImgWrap = styled.div<{ $ratio: number }>`
  width: 40vw;
  aspect-ratio: ${(_, { $ratio }) => $ratio};

  @media ${notDesktop} {
    width: 80vw;
  }
`

const RotatedArrow = styled(SvgArrow2)`
  transform: rotate(180deg);
`

const Wrap = styled.div`
  width: 100%;
`

const ModalSection = styled(Section)`
  margin: auto;
  position: relative;
`

const TopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  text-transform: uppercase;
`

const ItemsStack = styled(Stack)``

const IconLeft = styled(ButtonWithIcon)`
  min-width: 0;
  > div > div {
    background: #8c8c8c66;
  }
`

const IconRight = styled(ButtonWithIcon)`
  min-width: 0;

  > div > div {
    background: #8c8c8c66;
  }
`

const CloseButton = styled(Button)`
  svg {
    color: #8c8c8c66;
  }
`

type GalleryModalContextType = {
  value: {
    images: ImageAsset[] | null
    title: string | null
  }
  setValue: StateSetter<GalleryModalContextType['value']>
}

export const GalleryModalContext = createContext<GalleryModalContextType>({
  value: {
    images: null,
    title: null,
  },
  setValue: () => null,
})

export const useGalleryModal: () => GalleryModalContextType = () =>
  useContext(GalleryModalContext)

export function GalleryModalProvider({
  children,
}: {
  children: ReactElement[]
}): ReactElement {
  const [value, setValue] = useState<GalleryModalContextType['value']>({
    images: null,
    title: null,
  })

  const memodValue = useMemo(() => ({ value, setValue }), [value, setValue])

  return (
    <GalleryModalContext.Provider value={memodValue}>
      {children}
    </GalleryModalContext.Provider>
  )
}

export function GalleryModal(): ReactElement | null {
  const {
    value: { images, title },
    setValue,
  } = useGalleryModal()

  const open = images !== null && images.length > 0

  const widths = useMemo(
    () =>
      images?.map((image: { ratio?: number }) => {
        const ratio = image.ratio ?? 1
        return 560 * ratio
      }),
    [images],
  )

  return (
    <Context name="white">
      <Modal open={open}>
        <ModalSection
          outerCss={css`
            margin: auto;
            max-width: 100%;
          `}
        >
          <Wrap>
            <CarouselBase
              data={images ?? []}
              render={(image) => (
                <ImgWrap $ratio={image.ratio}>
                  <Image img={image} />
                </ImgWrap>
              )}
              plugins={[CarouselBase.plugins.autoWidth(widths)]}
              enhance={({ children, next, previous }) => (
                <>
                  <TopBar>
                    <Text tag="span">{title}</Text>
                    <ItemsStack>
                      <IconLeft onClick={previous} icon={SvgArrow2} />
                      <IconRight onClick={next} icon={RotatedArrow} reversed />
                      <CloseButton
                        kind={['icon', 'transparent']}
                        onClick={() => setValue({ images: null, title: null })}
                      >
                        <SvgCloseMenu />
                      </CloseButton>
                    </ItemsStack>
                  </TopBar>
                  {children}
                </>
              )}
              options={{
                breakpoints: {
                  [notMobile]: {
                    drag: false,
                    loop: true,
                    slides: {
                      perView: 'auto',
                      spacing: 32,
                      origin: 'center',
                    },
                  },
                  [notDesktop]: {
                    drag: true,
                    loop: true,
                    slides: {
                      perView: 1,
                      spacing: 16,
                    },
                  },
                },
              }}
            />
          </Wrap>
        </ModalSection>
      </Modal>
    </Context>
  )
}
