import { css, Global, styled } from "@obvio/app";
import { ObvioFooter } from "@obvio/ui";

import { Footer } from "./Footer";
import { MobilePanel } from "./MobilePanel";
import { FormContactModal } from "./Modals/FormContactModal";
import { GalleryModal, GalleryModalProvider } from "./Modals/GalleryModal";
import { Nav } from "./Nav";

import type { AllowUndefinedForOptional } from "@obvio/utils";
import type { ReactElement } from "react";

const StyledObvioFooter = styled(ObvioFooter)`
  margin: 0 auto;

  > a {
    color: white;
  }
`;

const globalStyle = css`
  body {
    background-color: ${(theme) => theme.background};
  }
  html {
    scroll-behavior: smooth;
  }
  ${(theme) => theme.global}
`;

type LayoutProps = AllowUndefinedForOptional<{
  children: ReactElement | ReactElement[];
}>;

export function Layout({ children }: LayoutProps): ReactElement {
  return (
    <GalleryModalProvider>
      <Nav />
      <main>{children}</main>
      <Footer />
      <StyledObvioFooter />
      <FormContactModal />
      <MobilePanel />
      <Global styles={globalStyle} />
      <GalleryModal />
    </GalleryModalProvider>
  );
}
